<template>
  <div>
    <slot name="header" />
    <div class="container mx-auto pb-12 max-w-3xl">
      <div class="text-center mt-12 mb-4">
        <a
          :href="data.field.buttonLink"
          primary
          large
          @click.prevent="handleClick"
          class="font-semibold focus:outline-none focus:shadow-outline whitespace-no-wrap rounded button-link--primary inline-block text-white bg-blue-500 hover:bg-blue-600 border-2 border-transparent sm:w-auto px-4 sm:px-24 py-4">
          {{ data.field.buttonText }}
        </a>
      </div>

      <slot
        :is-valid="false"
        :is-hidden="true"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default () {
        return {}
      }
    },

    formData: {
      type: Object,
      default () {
        return {}
      }
    },

    prev: {
      type: Function,
      default () {
        return () => {}
      }
    },

    submitting: {
      type: Boolean,
      default: false
    },

    submit: {
      type: Function,
      default () {
        return () => {}
      }
    }
  },

  methods: {
    handleClick () {
      try {
        window.dataLayer.push({
          event: "Clicked " + this.data.headline
        })
      } catch (e) {
        // do nothing
      }
      window.location.href = this.data.field.buttonLink
    }
  }
}
</script>

<style lang="scss" scoped>
  .button-link {
    &--primary {
      background-color: var(--button-primary-background-color);
      color: #fff;

      &:hover {
        background-color: var(--button-primary-background-color-hover);
      }
    }
  }
</style>
